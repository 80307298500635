.back-video {
  position: fixed;
  height: 100vh;
  width: auto;
  overflow: hidden;
}

.swipe-content {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swipe-content-card {
  width: 70%;
  height: 80%;
  background-color: #fffcf579;
  border-radius: 10px;
  border: 1px solid #df174b;
  box-shadow: 0px 10px 10px rgba(126, 126, 126, 0.646);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.swipe-content-card:hover {
  width: 75%;
  height: 85%;
  transition: 0.3s ease-in-out;
}

.swipe-content-card-img{
  height: 180px;
  overflow: hidden;
}

.swipe-content-card-main{
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.swipe-content-card-main h5{
  font-weight: 800;
}

.swipe-content-card-main button {
  position: absolute;
  bottom: -70px;
  left: 20px;
  right: 20px;
}

.swipe-content-card img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.product-header-title {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.product-header-title h1 {
  font-weight: 800;
  color: rgb(53, 53, 53);
  margin: 0px !important;
}

@media screen and (max-width: 767px) {
  .presentation-title {
    font-size: 3em;
  }
}
