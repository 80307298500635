.main {
  background-color: white;
  position: absolute;
  z-index: 1000;
  height: auto;
  padding: 40px 0px;
  width: 100%;
}

.fixed-top {
  background-image: url('/assets/img/Imagem3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.navbar-toggler span {
  background: white !important;
}

.logo-navbar img {
  width: 80px;
  height: 23px;
}

.fixed-top a {
  color: white !important;
}

.btn-round {
  background-color: transparent !important;
  border: none !important;
}

.product-detail {
  width: 100%;
  min-height: calc(100vh - 40vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-detail .col-md-6,
.product-detail .col-md-4,
.product-header .col-lg-4 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.product-detail .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.product-detail h2 {
  position: absolute;
  top: -100px;
  font-weight: 900;
  color: white;
}

.product-detail .col-md-4 {
  margin-top: 70px;
}

.col-product .swipe-content-card-img {
  height: auto !important;
}

.col-product .swipe-content-card {
  height: auto !important;
}

.col-product .swipe-content-card-main button {
  bottom: 7px !important;
}

.product-detail-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.product-detail-modal .row {
  width: 100%;
  margin: 0px !important;
}

.product-detail-modal .row:first-of-type {
  margin-bottom: 10px !important;
}

.product-detail-modal img {
  width: auto;
  max-width: 90%;
  height: 100%;
}

.close-modal {
  // position: absolute;
  z-index: 200000000;
  float: right;
  // right: 15px;
  // top: 5px;
  font-size: 20px;
  font-weight: 900;
  color: black;
  cursor: pointer;
}

.product-detail-modal img {
  margin-top: 15px;
  width: 100%;
  max-width: 100% !important;
}

.content-center {
  text-align: center;
}

.title-brand img {
  width: 400px;
  // height: 110px;
}

// .sub-title-brand {
//   // width: 130%;
//   // margin-left: -15%;
//   // display: flex;
//   // flex-direction: row;
//   // justify-content: space-between;
// }
.sub-title-brand h1 {
  font-weight: 900;
  // font-size: 40px;
  // opacity: 0.9;
  // color: black;
}

.content-center img {
  // width: 75%;
}

.product-content {
  margin-top: 40px;
  background: linear-gradient(90deg, #f946fc21 0%, #e83e8c59 100%);
}

.product-header .row {
  margin: 0px !important;
}

.col-product-bandas {
  min-height: 340px !important;
  height: auto !important;
}

.swipe-content-card-main h4 {
  color: black;
  font-weight: 900;
  margin: 0px !important;
}

.col-image-bandas img {
  width: 85%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(126, 126, 126, 0.646);
}

.col-image-bandas img:hover {
  width: 75%;
  height: 85%;
  transition: 0.3s ease-in-out;
}

.col-center {
  text-align: center;
  margin-bottom: 30px;
}

.col-bandas img {
  margin-top: 0px !important;
  max-height: 30vh;
  border-radius: 9px !important;
}

.scroll-div {
  max-height: 230px;
  overflow: scroll;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-banda-detail {
  color: black;
  font-weight: 900;
  margin-top: 5px !important;
  // margin-bottom: 10px;
  // margin: 0px !important;
}

.react-tabs {
  width: 100%;
  position: absolute;
  top: 20px;
  background-color: white !important;
}

.react-tabs__tab-list {
  text-align: center;
  border-bottom: 2px solid #df174b !important;
}

.react-tabs__tab-list h4 {
  margin: 0px !important;
  font-weight: 900;
}

.react-tabs__tab {
  bottom: -2px !important;
}

.react-tabs__tab--selected h4 {
  color: #df174b !important;
}

.react-tabs__tab--selected {
  // border-color: #884b96;
  border: 0px !important;
  border-bottom: 0px !important;
}

.titleLeds {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.titleLeds h4 {
  font-weight: 900;
  margin-bottom: 20px;
  color: #df3238;
}

.ReactModal__Content {
  border-radius: 16px !important;
  padding: 10px !important;
  // background: linear-gradient(90deg, #f946fc21 0%, #e83e8c59 100%) !important;
}

.bottom-buttons {
  width: 100%;
  margin-top: 20px;
}

.bottom-buttons a {
  width: 100%;
}

.bottomContactsMenu {
  position: absolute;
  bottom: 20px;
}

.bottomContactsMenu p {
  font-size: 11px;
}

.leaflet-container {
  position: none !important ;
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 100%;
  overflow: hidden;
  height: 500px;
  position: absolute;
  margin-top: 50px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  // opacity: 0;
  // animation: backInRight 1s 1.2s;
  // animation-fill-mode: forwards;
}

.info-map {
  position: absolute;
  background: #000;
  top: 50px;
  right: 30%;
  z-index: 1;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  border-radius: 0.5rem;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;

    span {
      color: #ffd700;
    }
  }
}

.content-Sobre {
  width: 65%;
  position: absolute;
  top: 50px;
  text-align: center;
  height: auto !important;
}

.content-Sobre p {
  font-size: 20px;
  margin-bottom: 25px;
}

.content-Sobre h5 {
  font-size: 30px;
  font-weight: 700;
}